import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faHome, faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons';

import logo from '../media/favicon.svg';

const currentYear = new Date().getFullYear();

function Footer() {

  return (
    <div className='footer' id='footer'>
      <div className='footer__top'>
        <div className='footer__left'>
          <div className='footer-adress'>
            <FontAwesomeIcon icon={faHome} />
            <p>
              <a href='https://goo.gl/maps/dQtryucXPBJpFdwZA'>
                Sparwasser Exklusive Edelstahl-Konstruktion nach Maß<br/>
                Oppenheimer Str. 13<br/>
                28307 Bremen
              </a>
            </p>
          </div>
          <div className='footer-phone'>
            <FontAwesomeIcon icon={faPhone} />
            <p>
              <a href='tel:01708638013'>
                0170 / 86 38 013
              </a>
            </p>
          </div>
          <div className='footer-mail'>
            <FontAwesomeIcon icon={faEnvelope} />
            <p>
              <a href="mailto:info@sparwasser-edelstahl.de">
                info@sparwasser-edelstahl.de
              </a>
            </p>
          </div>
        </div>
        <div className='footer__right'>
          <AnchorLink href='#start'>
            <div className='top' id='top'>
              <FontAwesomeIcon icon={faLongArrowAltUp} size='1x' />
            </div>
          </AnchorLink>
        </div>
      </div>
      <div className="footer__bottom">
        <div className='footer__right'>
          <p className='copyright'>
            &copy; {currentYear} Sparwasser. Alle Rechte vorbehalten.
          </p>
          <img src={logo} alt='favicon' />
        </div>
        <div className='footer__impressum'>
          <p>
            <a href='/impressum'>Impressum</a>
          </p>
        </div>
      </div>
  </div>
  );
}

export default Footer;
