import React from 'react';

function Impressum() {

    return (
        <div className='impressum'>
            <div className='impressum__content'>
                <div className='impressum__text'>
                    <div id='impressum' className='offset'/>
                    <h2>
                        Impressum
                    </h2>
                    <h3>
                        <b>Angaben gemäß § 5 TMG</b><br/>
                        <br/>
                        Sparwasser Exklusive Edelstahl-Konstruktion nach Maß<br/>
                        Oppenheimer Str. 13<br/>
                        28307 Bremen<br/>
                        <br/>
                        Handelsregister: *<br/>
                        Registergericht: *<br/>
                        <br/>
                        Vertreten durch:<br/>
                        Waldemar Sparwasser<br/>
                        <br/>
                        <b>Kontakt</b><br/>
                        Telefon: <a href='tel:01708638013'>0170 / 86 38 013</a><br/>
                        E-Mail: <a href="mailto:info@sparwasser-edelstahl.de">info@sparwasser-edelstahl.de</a><br/>
                        <br/>
                        <b>Umsatzsteuer-ID</b><br/>
                        Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:<br/>
                        *<br/>
                        <br/>
                    </h3>
                </div>
            </div>
        </div>
    );
}

export default Impressum;